:root {
 --primary-color-dark-blue: hsl(218, 28%, 13%);
 --primaryColor: #660000;
 --secondaryColor: #295f48;
 --white: #FFF;
   --m: 4rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  height: 100%;
}


#header{
  background-image:linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.5)),url(https://houseofrefugeglobal.org/image/legislative.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
  background-size:cover;
  background-repeat: no-repeat;
  background-position:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height:100vh;
  margin-top: -23px;
  margin-bottom: 54px;
}


.btn span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  top: 0; left: 0;
  width: 100%;
  padding: 15px 20px;
  transition: 0.3s;
}

.btn-1 span {
  color: #FFF;
  border: 1px solid var(--primaryColor);
  background-color: var(--primaryColor);
  transition: 0.2s 0.1s;
}
.btn-1 span:hover {
  color: white;
  transition: 0.2s 0.1s;
  background-color: var(--primaryColor);
}

.btn.hover-filled-slide-down::before {
  bottom: 0; left: 0; right: 0;
  height: 100%; width: 100%;
}
.btn.hover-filled-slide-down:hover::before {
  height: 0%;
}
#about{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 54px;
  /* height: 63vh; */
}

#about h1 {
  color: black;
  font-size: bold;
  text-align: center;
}

.event-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 27px;
}
.wrapper {
  background: var(--secondaryColor);
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 1.5em;
  width: 450px;
  height: 326px;
  border-radius: 10px;
  position: relative;
  z-index: 3
}
.upper {
  border-bottom: 1px solid rgba(255, 255, 255, .7);
  font-weight: 400;
  font-size: 24px;
  line-height: 2;
  text-transform: uppercase;
}

.meetup {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
}

.group {
  flex-grow: 1;
}

.details {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
}

.row-item {
  padding-left: 10px;
}


/* .card {
  height: calc(8 * var(--m));
  width: calc(12 * var(--m));
  background: linear-gradient(120deg, #ff8064, #725bdc);
  color: black;
  border-radius: calc(0.5 * var(--m));

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--m);

  position: fixed;
  margin: calc(2 * var(--m)) calc(5 * var(--m)) calc(5 * var(--m))
    calc(5 * var(--m));
} */

button {
  background-color: #000;
  font-size: calc(0.4 * var(--m));
  border: none;
  color: #e5e5e5;
  font-family: "IBM Plex Sans";
  font-weight: 400;
  padding: calc(0.35 * var(--m)) calc(0.8 * var(--m));
  border-radius: calc(0.3 * var(--m));
}

footer {
  margin-top: 0;
  z-index: 500;
  width: 100%;
  height: 50vh;
 background: #333;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  padding: 5rem 2vw;
  position: relative;
}

/* footer::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    rgba(0, 0, 0, 0) 5%,
    rgba(0, 0, 0, 0.3) 20%,
    rgba(0, 0, 0, 0.6) 30%,
    rgba(0, 0, 0, 0.8) 40%,
    rgba(0, 0, 0, 1) 50%,
    rgb(0, 0, 0)
  );
  z-index: -7;

} */

/* .backdrop {
  z-index: -5;
  position: absolute;
  inset: 0;

  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);

  mask-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5) 10%,
    rgba(0, 0, 0, 0.8) 20%,
    rgba(0, 0, 0, 1) 30%,
    rgb(0, 0, 0)
  );

  -webkit-mask-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5) 10%,
    rgba(0, 0, 0, 0.8) 20%,
    rgba(0, 0, 0, 1) 30%,
    rgb(0, 0, 0)
  );
} */

.col {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: calc(0.3 * var(--m)) calc(0.8 * var(--m));
  width: 28%;
}

.col2, .col3 {
  background-color: var(--primaryColor);
  margin: 9px;
  color: white;
  border-radius: calc(0.5 * var(--m))
}

img {
  height: calc(0.3 * var(--m));
  object-fit: cover;
}

.social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
}

a {
  text-decoration: none;
  color: inherit;

}

.link {
  width: calc(0.8 * var(--m));
  height: calc(0.8 * var(--m));
  background-color: rgba(255,255,255,0.1);
  border-radius: calc(0.1 * var(--m));

  display: flex;
  justify-content: center;
  align-items: center;
}


/* Team Members */

.gray {
  color: #a5a5a5;
}

.team{
  margin:40px;
}

.team-member {
  margin: 15px 0;
  padding: 0;
}

.team-member figure {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.team-member figure img {
  min-width: 100%;
  height: 45vh;
}

.team-member figcaption p {
  font-size: 16px;
}

.team-member figcaption ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.team-member figcaption ul {
  visibility: visible;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.team-member figcaption ul li {
  display: inline-block;
  padding: 10px;
}

.team-member h4 {
  margin: 10px 0 0;
  padding: 0;
}

.team-member figcaption {
  padding: 50px;
  color: transparent;
  background-color: transparent;
  position: absolute;
  z-index: 996;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45vh;
  overflow: hidden;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.team-member figure:hover figcaption {
  visibility: visible;
  color: #fff;
  background: rgba(41, 95, 72, 0.8);
  /* Primary color, can be changed via colors.css */

  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.team-member figure:hover figcaption ul li a:hover {
  color: rgba(49, 49, 49, .97);
}

.team-member figure img {
  -webkit-transform: scale(1) rotate(0) translateY(0);
  -moz-transform: scale(1) rotate(0) translateY(0);
  -o-transform: scale(1) rotate(0) translateY(0);
  -ms-transform: scale(1) rotate(0) translateY(0);
  transform: scale(1) rotate(0) translateY(0);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.team-member figure:hover img {
  -webkit-transform: scale(1.1) rotate(1deg) translateY(12px);
  -moz-transform: scale(1.1) rotate(1deg) translateY(12px);
  -o-transform: scale(1.1) rotate(1deg) translateY(12px);
  -ms-transform: scale(1.1) rotate(1deg) translateY(12px);
  transform: scale(1.1) rotate(1deg) translateY(12px);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

/* ABOUT  */
.about {
  width: 100%;
  height: 90vh;
}
.parallax{
    background: linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.7)),url('./assets/images/akpabio.jpeg') repeat fixed 100%;
    background-size: cover;
    color: aliceblue;
    height: 63vh;
    margin-top: -27px;
}
.parallax-1{
    background: linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.7)),url('./assets/images/senate.jpeg') repeat fixed 100%;
    background-size: cover;
    color: aliceblue;
    margin-top: -27px;
}
.parallax-3{
    background: linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.7)),url('./assets/images/akpabio.jpeg') repeat fixed 100%;
    background-size: cover;
    color: aliceblue;
}
.parallax-2{
    background: linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.7)),url('./assets/images/akpabio.jpeg') repeat fixed 100%;
    background-size: cover;
    color: aliceblue;
}
.parallax-inner{
    padding-top: 20%;
    padding-bottom: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.glass {
  width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.bold_class {
    font-weight: bold;
    color: #2b2b2b;
}

.pub {
  max-width: 860px;
  /* height: 45vh; */
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
  background-color: #fff;
  border-radius: 0.5em;
}

header > h1 {
  margin-top: 0;
  font-family: 'Catamaran', sans-serif;
  font-size: 2.5rem;
  border-bottom: 1px solid #eee;
}

.gallery {
  display: grid;
  margin: 3em 0;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 1em;
  grid-auto-flow: dense;
}

.gallery figure {
  display: flex;
  margin: 0;
}

.gallery img {
  flex: 1;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

@media screen and (max-width: 1000px) {
  :root {
    --m: 3rem;
  }
}


@media screen and (max-width: 700px) {
  footer {
    flex-direction: column;
    padding: 5rem 20vw;
    height: 120vh;
  }
  .col {
    width: 100%;
  }
  .about {
    width: 100%;
    height: 135vh;
  }
}

@media (max-width: 600px) {
  #header, section {
    margin-bottom: 72px;
  }
  .about {
    width: 100%;
    height: 135vh;
  }
}
